<template>
    <DynamicData :getData="() => fetchUserData(id)"
        :dataKey="'users-data-'+id" v-slot="{ data: userData, pending: userDataPending }">
        <div class="userdata w-10 h-10 shrink-0 mt-1 rounded-full flex items-center justify-center focus:outline-none bg-center bg-cover
            bg-secondary transition-transform duration-300 ease-over-out group-hover:scale-110"
            v-bind:class="{
                'animate-pulse bg-blue-100 dark:bg-gray-600' : userDataPending,
                'bg-primary' : !userDataPending && getProfilePicture(userData?.data?.[0]),
            }"
            v-bind:style="{ 'background-image' : getProfilePicture(userData?.data?.[0]) ? 'url('+getProfilePicture(userData?.data?.[0])+')' : '' }">
            <ElementsIcon v-if="userDataPending || !getProfilePicture(userData?.data?.[0])" icon="user" />
        </div>
    </DynamicData>
</template>
<script setup>
defineProps({
    id: { default: null, },
})
const { fetchUserData, getProfilePicture } = useUser()
</script>